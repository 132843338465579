import React, { useState, useEffect, useLayoutEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../../../components/layoutNoStripe";
import { SectionHeading } from "../../../styledComponents/section";
import ShowProgramfiles from "../../../components/Program/templates/showProgramFiles";
import { SectionAV } from "../../../styledComponents/ProgramAVContent";
import styled from "styled-components";
import { Spinner, SpinnerContainer } from "../../../styledComponents/spinner";
import { EventListSection } from "../../../styledComponents/eventList";
import ProductList from "../../../components/ProductListPurchased";
import { ProgramNav } from "../../../styledComponents/programCrumbNav";
import { Link } from "gatsby";
import { StyledButtonAsLink } from "../../../styledComponents/button";

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
);

const ProgramHealingAccessCodes = ({ data, location }) => {
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  let allproducts = data.allWpProducts.edges

  const fetchRoles = async () => {
    if (!isAuthenticated) {
      setRoles([]);
      // setLoadingRoles(false)
      setTimeout(() => setLoadingRoles(false), 3000); // Force delay for testing
      return;
    }

    try {
      const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];

      if (userRoles.length > 0) {
        setRoles(userRoles);
      } else {
        const token = await getAccessTokenSilently({ ignoreCache: true });
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
        setRoles(tokenRoles);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    } finally {
      setTimeout(() => setLoadingRoles(false), 3000); // Force delay for testing
    }
  };

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: window.location.origin + '/my-account/',
    });
  };

  useEffect(() => {
    fetchRoles();
    const interval = setInterval(() => {
      fetchRoles();
    }, 1000);
    return () => clearInterval(interval);
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      // Additional client-side logic if needed
    }
  }, []);

  // Helper function to check if user has a specific role
  const hasRole = (role) => roles.includes(role);

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      {isAuthenticated && 
      <ProgramNav><Link to="/my-account">Back to My Account</Link></ProgramNav>
      }
      <SectionAV className="av-program">
        <div className="react-tabs">
          <h2>Healing Access Codes</h2>
          {loadingRoles ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              {hasRole("Healing Access Codes Package A") ? (
                <ShowProgramfiles id="lL5kdcoxGAeE6L==" />
              ) : (
                <>
                  <p style={{ 'marginTop': '2rem' }}>You need to <StyledButtonAsLink href="/my-account" onClick={handleLogin}>login</StyledButtonAsLink> to access this program</p>
                </>
              )}
            </>
          )}
        </div>
      </SectionAV>
      {/* {isAuthenticated && (
            <>
              <EventListSection className="event-list">
              <Heading level={2} offScreen={false} align='left'>Other Programs and Meditations</Heading>       

                <ProductList posts={allproducts} roles={roles} isAuthenticated={isAuthenticated} user={user}/>
              </EventListSection>
            </>
          )} */}
    </LayoutNoStripe>
  );
};

export default ProgramHealingAccessCodes;

export const pageQuery = graphql`
  query WordPressProgramPage {
    allWpProducts: allWpProduct(
      sort: { order: ASC, fields: menuOrder }
      filter: { title: { ne: "Permission Bonus" } }
    ) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

